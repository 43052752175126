import { fetchAuthSession } from 'aws-amplify/auth';

async function currentSession() {
    try {
      const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};
      if (!accessToken || !idToken) {
        return { accessToken: null, idToken: null };
      }
      return { accessToken, idToken };
    } catch (err) {
      console.error("Error fetching current session:", err);
      throw err;
    }
  }

export { currentSession };