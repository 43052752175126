<template>
  <div class="skills">
    <h1>Skills</h1>

    <router-link :to="{ name: 'Applicants' }">
      Applicants
    </router-link>

    <div
      v-if="userCanUpload"
      id="upload-group"
      class="border"
    >
      <h3>Upload Skills File</h3>

      <p>Uploading the file will immediately edit the list of available groups and their skills.</p>

      <b-form-file
        id="skill-file-upload"
        v-model="uploadFile"
        :state="uploadSuccess"
        accept=".yaml, .yml"
        placeholder="Select or drop a skill definition YAML file"
        drop-placeholder="Drop YAML file here..."
        style="width: 50%"
      />
      <br>
      <b-button
        id="upload-button"
        :disabled="!Boolean(uploadFile) || uploading"
        @click="Upload"
      >
        <b-spinner
          v-if="uploading"
          small
          class="mb-1"
        />
        Upload
      </b-button>
      <br>
      <b-alert
        :show="uploadResult ? 5: 0"
        :variant="uploadResultType"
        fade
        dismissible
      >
        {{ uploadResult }}
      </b-alert>
    </div>

    <b-container
      id="skills-columns"
      :class="{'read-only': !userIsRecruiter}"
    >
      <b-row>
        <div v-for="groupObj in [current, legacy]" :key="groupObj.title">
          <b-col
            :id="groupObj.columnId"
            class="skills-column"
          >
            <h2
              :key="groupObj.title+'-title'"
              class="skills-group-title"
            >
              {{ groupObj.title }}
            </h2>
            <skills-display :groups="groupObj.groups" />
          </b-col>
        </div>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapState } from "pinia"
import { useStore } from "@/store"
import SkillsDisplay from "@/components/SkillsDisplay"
import axiosInstance from "@/vueAxiosConfig.js"

export default {

  name: "Skills",

  components: { SkillsDisplay },

  setup() {
    const store = useStore()
    return { store }
  },

  data() {
    return {
      uploadFile: null,
      uploadSuccess: true,
      uploadResult: null,
      uploading: false,
      current: {
        title: "Current Skills",
        groups: [],
        columnId: "col-current-skills"
      },
      legacy: {
        title: "Legacy Skills",
        groups: [],
        columnId: "col-legacy-skills"
      }
    }
  },

  computed: {
    ...mapState(useStore, ['userIsRecruiter']),

    uploadResultType() {
      return this.uploadSuccess ? "success" : "danger"
    },

    userCanUpload() {
      return this.userIsRecruiter
    }
  },

  created() {
    this.FetchSkills()
  },

  methods: {
    Upload() {
      if (this.uploading) {
        return
      }
      // !!! Validate?
      this.uploading = true
      this.uploadResult = null
      const reader = new FileReader()
      reader.onload = () => {
        const base64Contents = btoa(reader.result)
        axiosInstance.post("/skills/upload",
          {
            "base64SkillsYaml": base64Contents
          },
          {
            headers: {
              "Content-Type": "application/json"
            }
          })
          .then((data) => {
            this.uploadSuccess = true
            this.uploadResult = data
            this.FetchSkills()
          })
          .catch((error) => {
            this.uploadSuccess = false
            this.uploadResult = error
          })
          .finally(() => {
            this.uploading = false
          })
      }
      reader.readAsText(this.uploadFile)
    },

    FetchSkills() {
      axiosInstance.get("/skills")
        .then((data) => {
          const skills = data?.data

          // Group resulting skills by group. We're depending here on the
          // API returning skills ordered by group name then skill name.
          // Use separate arrays for current and legacy skills
          let currentGroups = [],
            legacyGroups = [],
            currIdx = -1,
            legcIdx = -1
          skills.forEach(skill => {
            if (skill.isCurrent) {
              if (-1 == currIdx || currentGroups[currIdx].name != skill.group) {
                currentGroups.push({ name: skill.group, skills: [] })
                currIdx = currentGroups.length - 1
              }
              currentGroups[currIdx].skills.push(skill)
            } else {
              if (-1 == legcIdx || legacyGroups[legcIdx].name != skill.group) {
                legacyGroups.push({ name: skill.group, skills: [] })
                legcIdx = legacyGroups.length - 1
              }
              legacyGroups[legcIdx].skills.push(skill)
            }
          })
          this.current.groups = currentGroups
          this.legacy.groups = legacyGroups
        })
    }
  }
}


</script>

<style scoped>
h1, h2 {
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

.category {
  width: 80px;
}

.skill {
  min-width: 80px;
}

.level {
  width: 120px;
}

.notes {
  min-width: 200px;
}

.categoryCell {
  font-weight: bold;
}

#upload-group {
  padding: 5px 0px 5px 0px;
  margin: 15px 0px 15px 0px;
}

#skills-columns {
  max-height: calc(100vh - 24rem);
  overflow-y: scroll;
  border-bottom: 2px solid #dee2e6;
  border-top: 2px solid #dee2e6;
  min-height: 15rem;
}

#skills-columns.read-only {
  max-height: calc(100vh - 12rem);
}
</style>
