<template>
  <div class="main">
    <div v-if="userIsInAnyGroup">
      <my-skills v-if="userIsApplicant" />
      <applicants v-else />
    </div>
    <div v-else>
      Incorrect credentials
    </div>
  </div>
</template>

<script>
import Applicants from "@/components/Applicants"
import MySkills from "@/components/MySkills"

import { mapState } from "pinia"
import { useStore } from "@/store"

export default {
  name: "Main",

  components: {
    Applicants,
    MySkills
  },
  props: { msg: String },

  setup() {
    const store = useStore()
    return { store }
  },

  data() {
    return {}
  },
  created() {
    // redirect to user landing if authenticated.
    // we can't do this in per-route beforeEnter() as
    // on initial login the Cognito callbacks won't have happened yet.
    if (this.signedIn && (this.userIsApplicant || this.userIsAnler)) {
      let redirect = localStorage.getItem("redirect")
      if (redirect) {
        localStorage.removeItem("redirect")
        this.$router.push({ path: redirect })
      } else {
        this.$router.push({ name: this.userIsApplicant ? "MySkills" : "Applicants" })
      }
    }
  },
  computed: {
    ...mapState(useStore, [
      "userIsAnler",
      "userIsApplicant",
      "signedIn",
      "userIsInAnyGroup"
    ])
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  margin: 10px;
  color: #42b983;
}
</style>
