<template>
  <div class="users">
    <router-link :to="{ name: 'Skills' }">
      All Skills
    </router-link>
    <h2>Search applicants by...</h2>

    <div v-if="userCanSearch">
      <b-tabs
        v-model="tabIndex"
        align="center"
        pills
        small
      >
        <applicant-search-tab
          :search-data="getUserSearchData()"
          :tab-active="selectedSearch() === SEARCH_TYPES.USER"
          @startSearch="startSearch"
        />

        <applicant-search-tab
          :search-data="getSkillsSearchData()"
          :tab-active="selectedSearch() === SEARCH_TYPES.SKILLS"
          @startSearch="startSearch"
        />

        <applicant-search-tab
          :search-data="getUserNoSkillsSearchData()"
          :tab-active="selectedSearch() === SEARCH_TYPES.NOSKILLS"
          @startSearch="startSearch"
        />
      </b-tabs>
    </div>
  </div>
</template>

<script>
import { storeToRefs, mapActions } from "pinia"
import { useStore } from "@/store"
import ApplicantSearchTab from "@/components/ApplicantSearchTab"
import { SEARCH_TYPES } from "@/utils/constants"

export default {
  name: "Applicants",

  components: {
    ApplicantSearchTab
  },

  setup() {
    const store = useStore()
    const { 
      userIsStaffingTeamMember, 
      userIsRecruiter, 
      personSearchProperties, 
      noSkillsSearchProperties, 
      skillsSearchProperties, 
      searchType 
    } = storeToRefs(store)
    
    return { 
      userIsStaffingTeamMember, 
      userIsRecruiter, 
      personSearchProperties, 
      noSkillsSearchProperties, 
      skillsSearchProperties, 
      searchType 
    }
  },

  data() {
    return {
      SEARCH_TYPES: SEARCH_TYPES,
      userSearchData: {
        searchType: SEARCH_TYPES.USER,
        apiPath: "/users",
        tabTitle: "Name or Email",
        subtitle: "by name or email",
        searchResults: [],
        haveSearched: false
      },
      skillSearchData: {
        searchType: SEARCH_TYPES.SKILLS,
        apiPath: "/users/skillsSearch",
        tabTitle: "Skills",
        subtitle: "by skill",
        searchResults: [],
        haveSearched: false
      },
      userNoSkillsSearchData: {
        searchType: SEARCH_TYPES.NOSKILLS,
        apiPath: "/users",
        tabTitle: "No Skills Entered",
        subtitle: "with no skills",
        searchResults: [],
        haveSearched: false
      },
      tabIndex: 0
    }
  },

  computed: {
    userCanSearch() {
      return this.userIsStaffingTeamMember || this.userIsRecruiter
    },

    userCanAddApplicant() {
      return this.userIsRecruiter
    }
  },

  methods: {
    ...mapActions(useStore, [
      'setPersonSearch', 
      'setSkillsSearch', 
      'setNoSkillsSearch', 
      'setSearchType',
      'search'
    ]),

    startSearch(searchData, event) {
      switch (searchData.searchType) {
        case SEARCH_TYPES.USER:
          this.startPersonSearch(event)
          this.setSearchType(searchData.searchType)
          break
        case SEARCH_TYPES.NOSKILLS:
          this.startPersonNoSkillsSearch(event)
          this.setSearchType(searchData.searchType)
          break
        case SEARCH_TYPES.SKILLS:
          this.startSkillSearch(event)
          this.setSearchType(searchData.searchType)
          break
        default:
          console.log(`Search Type ${searchData.searchType} not supported.`)
          break
      }
    },

    startPersonSearch(event) {
      let criteria = new URLSearchParams()

      if (event.statuses?.length > 0) {
        event.statuses.forEach((s) => criteria.append("status", s))
      }

      if (event.searchText) {
        criteria.append("text", event.searchText)
      }

      this.search({
        searchType: 'user',
        apiPath: this.userSearchData.apiPath,
        criteria
      })
    },

    startPersonNoSkillsSearch(event) {
      let criteria = new URLSearchParams()
      criteria.append("noSkills", "1")

      if (event.statuses?.length > 0) {
        event.statuses.forEach((s) => criteria.append("status", s))
      }

      this.search({
        searchType: 'noSkills',
        apiPath: this.userNoSkillsSearchData.apiPath,
        criteria
      })
    },

    startSkillSearch(event) {
      if (!event.searchText) {
        return this.startPersonSearch({})
      }

      let criteria = new URLSearchParams()
      
      if (event.statuses?.length > 0) {
        event.statuses.forEach((s) => criteria.append("status", s))
      }

      event.searchText
        .split(",")
        .map((skill) => skill.trim() + ">=" + event.level.id)
        .forEach((skillLevel) => criteria.append("sl", skillLevel))

      this.search({
        searchType: 'skills',
        apiPath: this.skillSearchData.apiPath,
        criteria
      })
    },

    getUserSearchData() {
      this.userSearchData.searchResults = this.personSearchProperties.results || []
      this.userSearchData.haveSearched = !!this.personSearchProperties.results
      return this.userSearchData
    },

    getUserNoSkillsSearchData() {
      this.userNoSkillsSearchData.searchResults = this.noSkillsSearchProperties.results || []
      this.userNoSkillsSearchData.haveSearched = !!this.noSkillsSearchProperties.results
      return this.userNoSkillsSearchData
    },

    getSkillsSearchData() {
      this.skillSearchData.searchResults = this.skillsSearchProperties.results || []
      this.skillSearchData.haveSearched = !!this.skillsSearchProperties.results
      return this.skillSearchData
    },

    selectedSearch() {
      return this.searchType || SEARCH_TYPES.SKILLS
    }
  }
}
</script>

<style scoped>
h1,
h2 {
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

.category {
  width: 80px;
}

.skill {
  min-width: 80px;
}

.level {
  width: 120px;
}

.notes {
  min-width: 200px;
}

.categoryCell {
  font-weight: bold;
}

table,
th,
td {
  border: 1px solid black;
}

td {
  padding: 2px;
}

#add-applicant-button {
  margin: 5px;
}

#any-status-checkbox {
  padding: 5px;
}

#standard-statuses-checkboxes {
  padding: 5px;
}
</style>