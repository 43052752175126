import { defineStore } from 'pinia'
import { currentSession } from '@/utils/auth.js'
import { getCurrentUser } from 'aws-amplify/auth'
import axiosInstance from '@/vueAxiosConfig'
import axios from 'axios'

let axiosInst = axiosInstance
const CancelToken = axios.CancelToken

const STAFFING_OR_RECRUITERS = ['StaffingTeam', 'Recruiters']

export const useStore = defineStore('main', {
  state: () => ({
    userSession: null,
    selectedSearch: "users",
    personSearch: {
      query: "",
      statusBoxes: ["Available"],
      results: null
    },
    skillsSearch: {
      query: "",
      statusBoxes: ["Available"],
      skillLevel: ["No Experience"],
      results: null
    },
    noSkillsSearch: {
      statusBoxes: ["Available"],
      results: null
    },
    searchStates: {
      user: {
        searching: false,
        cancelSearch: null
      },
      skills: {
        searching: false,
        cancelSearch: null
      },
      noSkills: {
        searching: false,
        cancelSearch: null
      }
    }
  }),

  getters: {
    session: (state) => state.userSession,
    userId: (state) => state.userSession ? state.userSession.idToken.payload["cognito:username"] : null,
    userEmail: (state) => state.userSession ? state.userSession.idToken.payload["email"] : null,
    signedIn: (state) => {
      return state.userSession !== null
    },
    userIsStaffingTeamMember: (state) => state.userSession?.idToken?.payload["cognito:groups"]?.includes("StaffingTeam") || false,
    userIsApplicant: (state) => state.userSession?.idToken?.payload["cognito:groups"]?.includes("Applicants") || false,
    userIsRecruiter: (state) => state.userSession?.idToken?.payload["cognito:groups"]?.includes("Recruiters") || false,
    userIsAnler: (state) => !!state.userSession && STAFFING_OR_RECRUITERS.some(group => state.userSession.idToken.payload["cognito:groups"]?.includes(group)),
    userIsInAnyGroup: (state) => !!state.userSession && !!state.userSession.idToken.payload['cognito:groups']?.length,
    UserInGroup: (state) => (group) => state.userSession?.idToken?.payload["cognito:groups"]?.includes(group) || false,
    UserInOneOfGroups: (state) => (groups) => {
      groups = groups || []
      const userGroups = state.userSession?.idToken?.payload["cognito:groups"] || []
      return groups.filter((group) => userGroups.includes(group)).length > 0
    },
    searchType: (state) => state.selectedSearch,
    personSearchProperties: (state) => state.personSearch,
    skillsSearchProperties: (state) => state.skillsSearch,
    noSkillsSearchProperties: (state) => state.noSkillsSearch
  },

  actions: {
    setUserSession(userSession) {
      if (userSession && userSession.idToken) {
        this.$patch({ userSession: Object.assign({}, userSession) });
        console.log("User session set in store:", userSession);
      } else {
        console.warn("Attempted to set invalid user session:", userSession);
      }
    },
    clearUserSession() {
      this.$patch({ userSession: null })
    },
    setSearchType(searchType) {
      this.selectedSearch = searchType
    },
    setPersonSearch(payload) {
      this.personSearch.query = payload.query
      this.personSearch.statusBoxes = payload.statusBoxes
      this.personSearch.results = payload.results
    },
    setSkillsSearch(payload) {
      this.skillsSearch.query = payload.query
      this.skillsSearch.statusBoxes = payload.statusBoxes
      this.skillsSearch.skillLevel = payload.skill_level
      this.skillsSearch.results = payload.results
    },
    setNoSkillsSearch(payload) {
      this.noSkillsSearch.statusBoxes = payload.statusBoxes
      this.noSkillsSearch.results = payload.results
    },
    async fetchUserSession() {
      try {
        const session = await currentSession()
        if (session && session.idToken) {
          this.setUserSession(session)
          console.log("User session fetched and stored:", session)
        } else {
          console.log("No valid session found")
          this.clearUserSession()
        }
      } catch (err) {
        console.error("fetchUserSession error:", err)
        this.clearUserSession()
      }
    },
    async search({ searchType, apiPath, criteria }) {
      const searchState = this.searchStates[searchType]
      
      if (searchState.cancelSearch) {
        searchState.cancelSearch()
        searchState.cancelSearch = null
        console.log(`${apiPath}: cancelling previous search`)
      }

      searchState.searching = true

      try {
        const { data } = await axiosInst.get(apiPath, {
          params: criteria,
          cancelToken: new CancelToken((c) => {
            searchState.cancelSearch = c
          })
        })

        switch (searchType) {
          case 'user':
            this.setPersonSearch({
              query: criteria.get("text"),
              statusBoxes: criteria.getAll("status"),
              results: data
            })
            break

          case 'skills':
            this.setSkillsSearch({
              query: criteria.get("text"),
              statusBoxes: criteria.getAll("status"),
              skill_level: criteria.getAll("sl"),
              results: data
            })
            break

          case 'noSkills':
            this.setNoSkillsSearch({
              statusBoxes: criteria.getAll("status"),
              results: data
            })
            break
        }

        return { succeeded: true, data }
      } catch (error) {
        if (!error.__CANCEL__) {
          console.error(error)
          return { succeeded: false, error }
        }
        console.log("cancelled search for " + apiPath)
        return { succeeded: false, cancelled: true }
      } finally {
        searchState.cancelSearch = null
        searchState.searching = false
      }
    },
    async editApplicant(applicant) {
      try {
        const { data } = await axiosInst.put(`/users/${applicant.id}`, { ...applicant })
        
        // Update the applicant in all search results
        const searches = ['personSearch', 'skillsSearch', 'noSkillsSearch']
        searches.forEach(searchType => {
          if (this[searchType].results) {
            const index = this[searchType].results.findIndex(a => a.id === applicant.id)
            if (index !== -1) {
              this[searchType].results[index] = { ...data }
            }
          }
        })

        console.log('Edit applicant response:', data)

        return { succeeded: true, data }
      } catch (error) {
        console.error('Edit applicant error:', error)
        return { succeeded: false, error }
      }
    },

    async deleteApplicant(applicantId) {
      try {
        await axiosInst.delete(`/users/${applicantId}`)
        
        // Remove the applicant from all search results
        const searches = ['personSearch', 'skillsSearch', 'noSkillsSearch']
        searches.forEach(searchType => {
          if (this[searchType].results) {
            this[searchType].results = this[searchType].results.filter(a => a.id !== applicantId)
          }
        })

        return { succeeded: true }
      } catch (error) {
        console.error('Delete applicant error:', error)
        return { succeeded: false, error }
      }
    },
    async addApplicant(applicant) {
      try {
        const { data } = await axiosInst.post("/users", applicant)
        
        // Add the new applicant to relevant search results if they match current criteria
        if (this.personSearch.results && 
            (this.personSearch.statusBoxes.length === 0 || this.personSearch.statusBoxes.includes(applicant.status)) &&
            (applicant.firstName + " " + applicant.lastName).toLowerCase()
              .includes(this.personSearch.query.toLowerCase())) {
          this.personSearch.results.push({ ...data, skills: [] })
        }

        if (this.noSkillsSearch.results && 
            (this.noSkillsSearch.statusBoxes.length === 0 || this.noSkillsSearch.statusBoxes.includes(applicant.status))) {
          this.noSkillsSearch.results.push({ ...data, skills: [] })
        }

        return { succeeded: true, data }
      } catch (error) {
        console.error('Add applicant error:', error)
        return { succeeded: false, error: String(error) }
      }
    }
  },

  persist: {
    enabled: true,
    strategies: [
      {
        storage: localStorage,
      },
    ],
  },
})

export default useStore