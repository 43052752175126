<template>
  <BTable
    :id="tableId"
    striped
    small
    no-border-collapse
    :busy="isSearching"
    :fields="tableFields"
    :items="applicantsData"
    :sort-compare="CompareApplicants"
  >
    <template #table-busy>
      <div class="text-center my-2">
        <BSpinner class="align-middle mr-2" />
        <strong class="loading-text">Loading...</strong>
      </div>
    </template>

    <template #cell(lastName)="data">
      <RouterLink
        :to="{ name: 'Applicant', params: { userId: data.item.id }}"
        exact
      >
        {{ data.value }}
      </RouterLink>
    </template>

    <template #cell(atsUrl)="data">
      <div v-if="data && data.value && data.value.length > 0">
        <template v-if="data.value.startsWith('https://artandlogic.greenhouse.io')">
          <a :href="data.value">Hire Profile</a>
        </template>
        <template v-else>
          Invalid URL
        </template>
      </div>
      <div v-else>
        None
      </div>
    </template>
  </BTable>
</template>

<script setup lang="ts">
import { BTable, BSpinner } from 'bootstrap-vue-next'
import { RouterLink } from 'vue-router'
import { computed, ref, watch } from 'vue'
import excitementLevelManager from "@/utils/excitementLevels"
import formatters from "@/utils/formatters"
import { useStore } from "../store"
import { SEARCH_TYPES } from "@/utils/constants"
import { storeToRefs } from 'pinia'

const props = defineProps({
  applicants: {
    type: Array,
    required: true
  },
  isSearching: {
    type: Boolean,
    default: false
  },
  isForNoSkills: {
    type: Boolean,
    default: false
  }
})

const store = useStore()
const { searchType, personSearchProperties, noSkillsSearchProperties } = storeToRefs(store)

const applicantsData = ref(props.applicants)

const tableId = computed(() => 
  props.isForNoSkills ? "person-noskills-search-table" : "person-search-table"
)

const searchProperties = computed(() => 
  props.isForNoSkills ? noSkillsSearchProperties.value : personSearchProperties.value
)

const tableFields = [
  {
    key: "lastName",
    label: "Full Name",
    formatter: FullNameFormatter,
    sortable: true,
    class: "userSearchCell"
  },
  {
    key: "dateCreated",
    label: "Date Added",
    formatter: DateFormatter,
    sortable: true,
    class: "userSearchCell"
  },
  {
    key: "dateLastUpdatedByUser",
    label: "Last Updated",
    formatter: DateFormatter,
    sortable: true,
    class: "userSearchCell"
  },
  {
    key: "status",
    sortable: true,
    class: "userSearchCell"
  },
  {
    key: "excitementLevel",
    label: "Excitement",
    formatter: ExcitementFormatter,
    sortable: true,
    class: "userSearchCell"
  },
  {
    key: "atsUrl",
    label: "Hire Profile",
    class: "userSearchCell",
    sortable: true
  }
]

function FullNameFormatter(value: any, key: string, item: any) {
  return item.firstName + " " + item.lastName
}

function DateFormatter(isoFormatVal: string) {
  return formatters.convertIsoFormattedUtcStringToLocalDateString(isoFormatVal)
}

function ExcitementFormatter(value: number) {
  const level = excitementLevelManager.normalizedExcitementLevel(value)
  return excitementLevelManager.labelForExcitementLevel(level)
}

function CompareApplicants(a: any, b: any, key: string) {
  if (key === "dateCreated" || key === "dateLastUpdatedByUser") {
    return ((b === a) ? 0 : ((a < b) ? 1 : -1))
  } else if (key === "lastName") {
    const astr = `${a["lastName"]}_${a["firstName"]}`
    const bstr = `${b["lastName"]}_${b["firstName"]}`
    return astr.localeCompare(bstr)
  }
  return null  // Default sorting
}

// Update applicantsData when props or store changes
watch(() => [props.applicants, searchType.value], () => {
  if (searchType.value === SEARCH_TYPES.USER || searchType.value === SEARCH_TYPES.NOSKILLS) {
    applicantsData.value = searchProperties.value.results || []
  } else {
    applicantsData.value = props.applicants
  }
})
</script>

<style scoped>
.userSearchCell {
  text-align: start;
}

:deep(.b-table-sticky-header) {
  max-height: none !important;
}
</style>