import { createApp } from "vue"
import {createBootstrap} from 'bootstrap-vue-next'
import App from "./App.vue"
import router from "./router"
import { Amplify } from "aws-amplify"
import { getCognitoValues } from "./awsconfig"
import { createPinia } from 'pinia'
import axiosInstance from "./vueAxiosConfig.js"
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';
import { defaultStorage } from 'aws-amplify/utils';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css'


async function initializeApp() {
  try {
    const response = await getCognitoValues();
    const userpool_id = response?.data?.VUE_APP_COGNITO_USERPOOL_ID;
    const client_id = response?.data?.VUE_APP_COGNITO_CLIENT_ID;
    const domain = response?.data?.VUE_APP_COGNITO_APP_DOMAIN;

    Amplify.configure({
      Auth: {
        Cognito: {
          region: import.meta.env.VITE_APP_AWS_REGION,
          userPoolId: userpool_id,
          userPoolClientId: client_id,
          signUpVerificationMethod: 'code',
          loginWith: {
            oauth: {
              domain: domain,
              scopes: ["email", "openid", "aws.cognito.signin.user.admin"],
              redirectSignIn: [import.meta.env.VITE_APP_BASE_URL],
              redirectSignOut: [import.meta.env.VITE_APP_BASE_URL],
              responseType: "code"
            }
          }
        }
      }
    });

    cognitoUserPoolsTokenProvider.setKeyValueStorage(defaultStorage);

    const currentConfig = Amplify.getConfig();
    console.log("Current Amplify config:", currentConfig);

    const app = createApp(App);

    app.config.errorHandler = (err, vm, info) => {
      console.error('Vue error: ', err)
      console.error('Error occurred in component: ', vm)
      console.error('Error info: ', info)
    }

    app.use(createBootstrap());
    app.use(router);
    const pinia = createPinia();
    pinia.use(piniaPluginPersistedstate);
    app.use(pinia);
    app.config.globalProperties.$http = {
      get: axiosInstance.get.bind(axiosInstance),
      post: axiosInstance.post.bind(axiosInstance),
      put: axiosInstance.put.bind(axiosInstance),
      delete: axiosInstance.delete.bind(axiosInstance)
    };
    app.mount("#app");
  } catch (error) {
    console.error("Failed to initialize app:", error);
  }
}



initializeApp();