// Confguration docs:
// https://aws-amplify.github.io/docs/js/authentication#manual-setup

import axios from "axios"


export function getCognitoValues() {
  // If we're running dev mode, we'll return the cognito exports from environment variables.
  if (import.meta.env.MODE === "development") {
      let response = {
        VUE_APP_COGNITO_USERPOOL_ID: import.meta.env.VITE_APP_USER_POOL_ID,
        VUE_APP_COGNITO_CLIENT_ID: import.meta.env.VITE_APP_USER_POOL_CLIENT_ID,
        VUE_APP_COGNITO_APP_DOMAIN: import.meta.env.VITE_APP_USER_POOL_DOMAIN
      }

      return Promise.resolve({
          "data": response
      })
  }
  // Otherwise, we'll return the cognito exports from a json file.
  return axios.get("/cognitoExports.json")
}

