// Formatting functions

/**
 * Given a UTC datetime as sent by the API, return the corresponding
 * browser-local date.
 */
const convertIsoFormattedUtcStringToLocalDateString = function(isoFormatVal) {
  // Don't rely on new Date(isoFormatVal), but instead parse the UTC
  // datetime as passed by the API (which as of 11/24/2020 doesn't
  // include timezone information) by
  //   * splitting the string into an array of digit strings,
  //   * explicitly passing the numeric-converted digit strings to Date.UTC(),
  //   * pass the resulting milliseconds to new Date() for local date/time,
  // and return its toLocaleDateString() (e.g., 12/25/2000).
  let dt = isoFormatVal.split(/\D/),
    utcTime = Date.UTC(+dt[0], dt[1] - 1, +dt[2], +dt[3], +dt[4], +dt[5], 0)
  return (new Date(utcTime)).toLocaleDateString()
}

export default {
  convertIsoFormattedUtcStringToLocalDateString
}
