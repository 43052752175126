// manages static list of "Excitement Levels", 
// and utilities to normalize and display excitementLevel data.

const EXCITEMENT_LOW = 1
const EXCITEMENT_MEDIUM = 2
const EXCITEMENT_HIGH = 3

const EXCITEMENT_LABELS = [
  "Low",
  "Medium",
  "High"
]

const EXCITEMENT_NOT_SET = "Not Set"

const EXCITEMENT_LEVELS = [
  {
    value: EXCITEMENT_LOW,
    label: EXCITEMENT_LABELS[EXCITEMENT_LOW - 1]
  },
  {
    value: EXCITEMENT_MEDIUM,
    label: EXCITEMENT_LABELS[EXCITEMENT_MEDIUM - 1]
  },
  {
    value: EXCITEMENT_HIGH,
    label: EXCITEMENT_LABELS[EXCITEMENT_HIGH - 1]
  }
]

/**
 * Return label for the level (or "Not Set" if empty)
 *
 * @param {String | Integer} level
 */
const labelForExcitementLevel = function(level) {

  if (!level) {
    return EXCITEMENT_NOT_SET
  }

  return EXCITEMENT_LABELS[level - 1] || EXCITEMENT_NOT_SET
}

/**
 * normalizes excitement level to our allowed integer values
 *
 * @param {Integer | String} level
 */
const normalizedExcitementLevel = function(level) {
  if (null === level || "" === level) {
    return null
  }

  level = parseInt(level)
  if (isNaN(level) || level < EXCITEMENT_LOW || level > EXCITEMENT_HIGH) {
    level = EXCITEMENT_MEDIUM
  }
  return level
}

export default {
  EXCITEMENT_LEVELS,
  EXCITEMENT_LOW,
  EXCITEMENT_MEDIUM,
  EXCITEMENT_HIGH,
  labelForExcitementLevel,
  normalizedExcitementLevel
}