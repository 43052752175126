<template>
  <b-tab
    :title="searchData.tabTitle"
    :active="tabActive"
  >
    <person-search-form
      v-if="searchData.searchType === SEARCH_TYPES.USER || searchData.searchType === SEARCH_TYPES.NOSKILLS"
      :searching="searchData.searching"
      :is-for-no-skills="searchData.searchType === SEARCH_TYPES.NOSKILLS"
      @person-search="startSearch($event)"
    />

    <skill-search-form
      v-else
      :searching="searchData.searching"
      @skill-search="startSearch($event)"
    />

    <b-container>
      <b-row align-h="center">
        <b-col>
          <div class="tab-subtitle">
            Search results ({{ searchData.subtitle }})
          </div>
        </b-col>

        <b-col v-if="userCanAddApplicant">
          <router-link
            id="add-applicant-button"
            :to="{ name: 'AddApplicant' }"
            tag="button"
            class="mt-1"
          >
            Add Applicant
          </router-link>
        </b-col>
        <b-col v-else />
        <!-- empty to keep the layout the same when this case occurs -->
      </b-row>
    </b-container>
    <div
      :class="{ 'results-container': true, 'have-results': haveSearchResults }"
    >
      <person-search-table
        v-if="searchData.searchType === SEARCH_TYPES.USER || searchData.searchType === SEARCH_TYPES.NOSKILLS"
        :applicants="searchData.searchResults"
        :is-searching="searchData.searching"
        :is-for-no-skills="searchData.searchType === SEARCH_TYPES.NOSKILLS"
      />

      <skill-search-table
        v-else
        :user-skills="searchData.searchResults"
        :is-searching="searchData.searching"
      />
      <div
        v-if="!searchData.haveSearched"
        class="no-search-yet"
      >
        Please make a search selection
      </div>
    </div>
    <div v-show="searchData.lastRequestError !== null">
      <b-alert
        show
        variant="danger"
        class="errors-display"
        fade
      >
        {{
          searchData.lastRequestError
        }}
      </b-alert>
    </div>
  </b-tab>
</template>
<script>
import { mapState } from "pinia"
import { useStore } from "@/store"
import PersonSearchForm from "@/components/PersonSearchForm"
import PersonSearchTable from "@/components/PersonSearchTable"
import SkillSearchForm from "@/components/SkillSearchForm"
import SkillSearchTable from "@/components/SkillSearchTable"
import { SEARCH_TYPES } from "@/utils/constants"

export default {
  name: "ApplicantSearchTab",

  components: {
    PersonSearchForm,
    PersonSearchTable,
    SkillSearchForm,
    SkillSearchTable
  },

  props: {
    searchData: Object,
    tabActive: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const store = useStore()
    return { store }
  },
  computed: {
    ...mapState(useStore, ['userIsRecruiter']),

    userCanAddApplicant() {
      return this.userIsRecruiter
    },
    haveSearchResults() {
      return this.searchData && this.searchData.searchResults.length
    }
  },
  methods: {
    startSearch(event) {
      this.$emit("startSearch", this.searchData, event)
    }
  },
  data() {
    return {
      SEARCH_TYPES: SEARCH_TYPES
    }
  }
}
</script>

<style scoped>
.tab-subtitle {
  font-size: 1.5em;
  font-weight: lighter;
}

.no-search-yet {
  font-size: 1em;
  font-weight: lighter;
  font-style: italic;
}

.results-container {
  border-bottom: 2px solid #dee2e6;
  border-top: 2px solid #dee2e6;
}

</style>