import { currentSession } from "./utils/auth.js"
import axios from "axios"
import { useStore } from "./store.js"

const axiosInstance = axios.create({
  baseURL: import.meta.env.VITE_APP_API_URL
})

axiosInstance.interceptors.request.use(async (config) => {
  const store = useStore()
  try {
    const userSession = currentSession()
    console.log("User session:", userSession)
    if (userSession) {
      config.headers.Authorization = (await userSession).idToken
    }
  } catch (error) {
    console.log("Error setting Authorization header:", error)
    store.clearUserSession()
  }
  
  return config
})

export default axiosInstance