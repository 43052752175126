<template>
  <div id="app">
    <div
      v-if="isReady"
      class="main-content"
    >
      <div v-if="signedIn">
        <div
          id="signed-in-as"
          class="mb-1"
        >
          <div>
            <span class="static-text" />
            <span class="user-email">{{ userEmail }}</span>
          </div>
          <BLink
            id="sign-out-button"
            @click="signOutUser"
          >
            Sign Out
          </BLink>
        </div>
        <div class="container">
          <router-view />
        </div>
      </div>

      <div v-else>
        <b-button
          id="sign-in-button"
          class="signIn"
          @click="signIn"
        >
          Sign In
        </b-button>
      </div>
    </div>
    <div
      v-else
      class="main-content"
    >
      Loading ...
    </div>

    <footer>
      Build {{ buildCommitId }} {{ buildDateTime }}
    </footer>
  </div>
</template>

<script>
import { useStore } from "./store.js"
import { signInWithRedirect, signOut, } from "aws-amplify/auth"
import { Hub } from 'aws-amplify/utils';
import { mapState, mapActions } from 'pinia'
import { currentSession } from "@/utils/auth"

export default {
  name: "App",

  components: {},

  data() {
    return {
      ready: false,
      buildDateTime: import.meta.env.VITE_APP_BUILD_DATETIME,
      buildCommitId: import.meta.env.VITE_APP_BUILD_COMMIT_SHA?.substr(0, 8)
    }
  },

  computed: {
    ...mapState(useStore, ['signedIn', 'userEmail']),

    isReady() {
      return true
    }
  },

  beforeCreate() {
    const store = useStore()
    // Listen to Amplify Hub auth events
    Hub.listen("auth", ({ payload: { event, data, message } }) => {
      console.log("Hub auth event:", event)
      switch (event) {
        case "signedIn":
          store.fetchUserSession()
          break

        case "cognitoHostedUI":
          // Because we're using Cognito hosted UI, this event is
          // emitted along with "signIn". We can ignore.
          break

        case "oAuthSignOut": // fall through
        case "signOut":
          console.log("case", event)
          store.clearUserSession()
          break

        case "signIn_failure":          // fall through
        case "cognitoHostedUI_failure": // fall through
        case "customState_failure":
          console.log("Auth event failure:", event, "data:", data, "message:", message)
          break

        default:
          console.log("Unhandled Auth event:", event, "data:", data, "message:", message)
          break
      }
    })
  },

  beforeMount() {
    this.ready = false
    const store = useStore()
    currentSession()
      .then((session) => {
        console.log("currentSession() session:", session)
        if (session && session.idToken) {
          store.setUserSession(session)
          this.ready = true
        } else {
          console.warn("Session found, but no idToken")
          store.clearUserSession()
          this.ready = true
        }
      })
      .catch((error) => {
        console.error("currentSession() error:", error)
        store.clearUserSession()
        this.ready = true
      })
  },

  methods: {
    ...mapActions(useStore, ['setUserSession', 'clearUserSession', 'fetchUserSession']),

    signIn: async function() {
      try {
        this.ready = false;
        const session = await currentSession();
        if (session && session.idToken) {
          console.log("User is already signed in");
          this.setUserSession(session);
        } else {
          console.log("Triggering Auth.federatedSignIn()");
          await signInWithRedirect();
        }
      } catch (err) {
        console.log("Error during sign in process:", err);
        if (err.name === 'NotAuthorizedException') {
          await signInWithRedirect();
        }
      } finally {
        this.ready = true;
      }
    },
    signOutUser: function() {
      this.ready = false
      console.log("Triggering Auth.signOut()")
      this.clearUserSession()
      signOut()
        .then(() => {
          console.log("User signed out")
        })
        .catch(err => console.log("Auth.signOut error:", err))
    }
  }
}
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 10px 20px 0px 20px;
}

a.router-link-active {
  color: #42b983;
  margin: 10px;
}

.signIn {
  margin-top: 50px;
}

.signOut {
}

footer {
  font-size: x-small !important;
}

#signed-in-as {
  text-align: right;
  line-height: 1.5;
  font-size: 0.85em;
}

.main-content {
  min-height: calc(100vh - 28px);
}
</style>
