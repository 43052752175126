<template>
  <b-dropdown
    id="excitement-level-dropdown"
    :text="excitementLevelLabel(this.applicant.excitementLevel)"
    lazy
  >
    <div v-for="excitementLevel in excitementLevels" :key="excitementLevel.value">
      <BDropdownItemButton
        
        class="excitement-level-dropdown-item"
        @click="$emit('excitement-level-changed', excitementLevel.value)"
      >
        {{ excitementLevel.label }}
      </BDropdownItemButton>
    </div>
  </b-dropdown>
</template>

<script>

import excitementLevelManager from "@/utils/excitementLevels"

export default {
  name: "ExcitementLevelMenu",

  props: {
    "applicant": Object
  },

  data() {
    return {
      excitementLevels: excitementLevelManager.EXCITEMENT_LEVELS
    }
  },

  methods: {
    excitementLevelLabel(level) {
      return excitementLevelManager.labelForExcitementLevel(level)
    }
  }
}
</script>
