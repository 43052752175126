import { createRouter, createWebHistory } from 'vue-router'
import AddApplicant from "@/components/AddApplicant"
import Main from "@/components/Main"
import MySkills from "@/components/MySkills"
import Skills from "@/components/Skills"
import Applicants from "@/components/Applicants"
import Applicant from "@/components/Applicant"
import TestAuthorization from "@/components/TestAuthorization"
import { useStore } from "../store.js"

import {currentSession} from '@/utils/auth.js'

const STAFFING_OR_RECRUITERS = ['StaffingTeam', 'Recruiters']

const router = createRouter({
   history: createWebHistory(),
   routes: [
      {
         path: '/',
         name: 'Main',
         component: Main,
         meta: { requiresAuth: false },
      },
      {
         path: '/myskills',
         name: 'MySkills',
         component: MySkills,
         meta: { requiresAuth: true,
                 requiresGroups: ['Applicants'] },      
      },
      {
         path: '/skills',
         name: 'Skills',
         component: Skills,
         meta: { requiresAuth: true,
                 requiresGroups: STAFFING_OR_RECRUITERS },
      },
      {
         path: '/applicants',
         name: 'Applicants',
         component: Applicants,
         meta: { requiresAuth: true,
                 requiresGroups: STAFFING_OR_RECRUITERS },
      },
      {
         path: '/applicants/add',
         name: 'AddApplicant',
         component: AddApplicant,
         meta: { requiresAuth: true,
                 requiresGroups: ['Recruiters'] },
      },
      {
         path: '/applicants/:userId',
         name: 'Applicant',
         component: Applicant,
         props: true,
         meta: { requiresAuth: true,
                 requiresGroups: STAFFING_OR_RECRUITERS  },
      },
      {
         path: '/testAuthorization',
         name: 'TestAuthorization',
         component: TestAuthorization,
         meta: {
            requiresAuth: true,
            requiresGroups: STAFFING_OR_RECRUITERS
         },
      },
   ]
});

const defaultPathForSession = function() {
  const store = useStore()
  let name = "Main"
  if (store.signedIn) {
    name = store.userIsApplicant ? "MySkills" :
      store.userIsAnler ? "Applicants" : name
  }
  return name
}


// Require authenticated user to access routes with meta.requiresAuth
router.beforeResolve(async (to, from, next) => {

  // If the route requires auth, check if the user is signed in
  if (to.matched.some(record => record.meta.requiresAuth)) {

    if (!currentSession()) {
      // If the user is not signed in, redirect to the login page
      next({
        name: 'Main',
        query: { redirect: to.fullPath }
      })
    } else {
      // Otherwise, continue to the next route
      next()
    }
  } else {
    // If the route does not require auth, continue to the next route
    next()
  }
  
})

export default router
